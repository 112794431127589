import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Redirect, useParams } from "react-router-dom";
import { Retry, Status } from "../../components/Lazy/Retry";
import { dataLibs } from "../../data/dataLibs";
import { Page } from "../Page";
import { Map } from "./Map";
import {
  deviceWidthState,
  isWideDevice,
  NAMED_DEVICE_WIDTH,
} from "../../state/uiState";
import { dataMaps, MapSide, Story } from "../../data/dataMaps";
import { MobileHeader } from "../../components/Header/MobileHeader";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mapActiveState, mapSideState } from "../../state/mapState";
import { MapInfoToggler } from "../../components/Map/MapInfoToggler";
import { MapDesktopHint } from "../../components/Map/MapDesktopHint";
import { Info } from "./Info";
import { getDynamicLink } from "../../data/routeUtils";
import "./HundredYearsWar.scss";
import "./legends/Legends.scss";
import {
  Header1337,
  DataUrl1337,
  Audio1337,
  Sources1337,
  Highlights1337,
  Legends1337,
} from "./years/Year1337";
import {
  Audio1360,
  DataUrl1360,
  Header1360,
  Highlights1360,
  Sources1360,
} from "./years/Year1360";
import {
  Audio1388,
  DataUrl1388,
  Header1388,
  Highlights1388,
  Legends1388,
  Sources1388,
} from "./years/Year1388";
import {
  Audio1429,
  DataUrl1429,
  Header1429,
  Highlights1429,
  Legends1429,
  Sources1429,
} from "./years/Year1429";
import {
  Header1453,
  DataUrl1453,
  Sources1453,
  Audio1453,
  Highlights1453,
  Legends1453,
} from "./years/Year1453";

export const ROUTE = "/hundred-years-war/:year?";
const hundredYearsWarStory = {
  dataUrls: [
    "/maps/HundredYearsWar/land_54_-5.77_8.5_41.5.json",
    // "/maps/HundredYearsWar/rivers_54_-5.77_8.5_41.5.json",
  ],
  imageUrl: "/maps/HundredYearsWar/final_54_-5.77_8.5_41.5.webp",
  wideDeviceDataUrls: [
    "/maps/HundredYearsWar/land_60_-11_14_35.json",
    // "/maps/HundredYearsWar/rivers_60_-11_14_35.json",
  ],
  wideDeviceImageUrl: "/maps/HundredYearsWar/final_60_-11_14_35.webp",
  years: {
    "1337": {
      year: 1337,
      header: Header1337,
      territoriesUrl: DataUrl1337,
      audio: Audio1337,
      sources: Sources1337,
      highlights: Highlights1337,
      legends: Legends1337,
    },
    "1360": {
      year: 1360,
      header: Header1360,
      territoriesUrl: DataUrl1360,
      sources: Sources1360,
      audio: Audio1360,
      highlights: Highlights1360,
      legends: Legends1337,
    },
    "1388": {
      year: 1388,
      header: Header1388,
      territoriesUrl: DataUrl1388,
      sources: Sources1388,
      audio: Audio1388,
      highlights: Highlights1388,
      legends: Legends1388,
    },
    "1429": {
      year: 1429,
      header: Header1429,
      territoriesUrl: DataUrl1429,
      sources: Sources1429,
      audio: Audio1429,
      highlights: Highlights1429,
      legends: Legends1429,
    },
    "1453": {
      year: 1453,
      header: Header1453,
      territoriesUrl: DataUrl1453,
      sources: Sources1453,
      audio: Audio1453,
      highlights: Highlights1453,
      legends: Legends1453,
    },
  },
};

const fetchedYears: { [key: string]: any } = {};

function getImageUrl(story: Story, namedWidth: NAMED_DEVICE_WIDTH) {
  return isWideDevice(namedWidth) ? story.wideDeviceImageUrl : story.imageUrl;
}

function getPromises(story: Story, namedWidth: NAMED_DEVICE_WIDTH) {
  if (isWideDevice(namedWidth)) {
    return [
      dataLibs.initD3(),
      dataLibs.initTopojson(),
      dataMaps.getTopo(story.wideDeviceDataUrls[0]),
      // dataMaps.getTopo(story.wideDeviceDataUrls[1]),
    ];
  } else {
    return [
      dataLibs.initD3(),
      dataLibs.initTopojson(),
      dataMaps.getTopo(story.dataUrls[0]),
      // dataMaps.getTopo(story.dataUrls[1]),
    ];
  }
}

export const HundredYearsWar: React.FunctionComponent = () => {
  const namedWidth = useRecoilValue(deviceWidthState);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [data, setData] = useState<any[]>();
  // const [territories, setTerritories] = useState<any>();
  const { year } = useParams<{
    year: keyof typeof hundredYearsWarStory.years;
  }>();
  const setMapSide = useSetRecoilState(mapSideState);
  const [active, setActive] = useRecoilState(mapActiveState);
  const story = hundredYearsWarStory;
  const [loading, setLoading] = useState<boolean>(true);
  const hoveredBeforeLoaded = useRef<boolean>(false);
  const [dynamic, setDynamic] = useState<{
    territories: any;
    year: keyof typeof hundredYearsWarStory.years;
  }>();

  const load = useCallback(
    (width) => {
      const promises = getPromises(story, width);
      Promise.all(promises)
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          console.error(err);
          setStatus(Status.ERROR);
        });
    },
    [story]
  );

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => load(namedWidth), 1000);
  }, [load, namedWidth]);

  useEffect(() => {
    load(namedWidth);
  }, [load, namedWidth]);

  useEffect(() => {
    setMapSide(MapSide.RIGHT);
  }, [setMapSide]);

  useEffect(() => {
    if (data) {
      setStatus(Status.SUCCESS);
    }
  }, [data]);

  useEffect(() => {
    if (!year || !story.years[year]) {
      return;
    }

    // if (fetchedYears[year]) {
    //   // setTerritories(fetchedYears[year]);
    //   setDynamic({
    //     year,
    //     territories: fetchedYears[year],
    //   });
    //   return;
    // }

    setLoading(true);
    let animationDone = false;
    let fetchDone = false;
    let territories: any;

    window.setTimeout(() => {
      animationDone = true;
      if (fetchDone) {
        if (hoveredBeforeLoaded.current) {
          hoveredBeforeLoaded.current = false;
          setActive(story.years[year].highlights.all);
        }
        setDynamic({
          year,
          territories,
        });
        return;
      }
    }, 350);

    dataMaps
      .getTopo(story.years[year].territoriesUrl)
      .then((territoriesResponse) => {
        fetchedYears[year] = territoriesResponse;
        fetchDone = true;
        if (animationDone) {
          if (hoveredBeforeLoaded.current) {
            hoveredBeforeLoaded.current = false;
            setActive(story.years[year].highlights.all);
          }
          setDynamic({
            year,
            territories: territoriesResponse,
          });
        } else {
          territories = territoriesResponse;
        }
      })
      .catch((err) => {
        console.error(err);
        // Todo, retry?
      });
  }, [year, story, setActive]);

  useEffect(() => {
    if (dynamic) {
      setLoading(false);
    }
  }, [dynamic]);

  const onHover = useCallback(() => {
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      const dynamicYear = dynamic?.year;
      if (dynamicYear) {
        setActive(story.years[dynamicYear]?.highlights.all);
      } else {
        hoveredBeforeLoaded.current = true;
        setActive(null);
      }
    }
  }, [setActive, dynamic, story]);

  const offHover = useCallback(() => {
    hoveredBeforeLoaded.current = false;
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      setActive(null);
    }
  }, [setActive]);

  if (!year || !story.years[year]) {
    return (
      <Redirect
        to={getDynamicLink(ROUTE, { year: Object.keys(story.years)[0] })}
      />
    );
  }

  const yearHighlights = dynamic?.year
    ? story.years[dynamic?.year]?.highlights
    : undefined;

  return (
    <Page
      title="Interactive map of the Hundred Years' War"
      canonical={`https://interactive-history.app/hundred-years-war/${year}`}
      description="Interactive map showing the advances of the belligerents in the Hundred Years' War"
      className={cx("hundred-years-war", {
        "is-loading": loading,
      })}
      mapSide={MapSide.RIGHT}
    >
      <img
        className="searchable-image"
        loading="lazy"
        src="/images/hundred_930x930.webp"
        alt="Estimated borders of the belligerents in the Hundred Years' War"
      />
      <Retry status={status} retry={retry}>
        <>
          <MobileHeader year={year} title="Hundred Years' War" />

          <div
            className="map-wrapper"
            onMouseEnter={onHover}
            onMouseLeave={offHover}
          >
            <Map
              isWideDevice={isWideDevice(namedWidth)}
              land={data && data[2]}
              // rivers={data && data[3]}
              territories={dynamic?.territories}
              imageUrl={getImageUrl(story, namedWidth)}
              highlights={yearHighlights}
              isActive={active}
            />

            <MapDesktopHint />

            <MapInfoToggler highlights={yearHighlights} />
          </div>

          <div className="info-wrapper">
            <Info
              year={dynamic?.year || Object.keys(story.years)[0]}
              story={story}
            />
          </div>
        </>
      </Retry>
    </Page>
  );
};
