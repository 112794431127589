import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Page } from "../Page";
import { dataMaps, MapSide, SimpleStory } from "../../data/dataMaps";
import { dataLibs } from "../../data/dataLibs";
import { useRecoilState } from "recoil";
import { Retry, Status } from "../../components/Lazy/Retry";
import { mapActiveState } from "../../state/mapState";
import { MapDesktopHint } from "../../components/Map/MapDesktopHint";
import { MobileHeader } from "../../components/Header/MobileHeader";
import { Map } from "./Map";
import {
  Header1870,
  DataUrl1870,
  Audio1870,
  Sources1870,
  Highlights1870,
  Legends1870,
  Additional1870,
} from "./years/Year1870";
import "./SupernovaInTheEast.scss";
import {
  Header1905,
  DataUrl1905,
  Audio1905,
  Sources1905,
  Highlights1905,
  Legends1905,
} from "./years/Year1905";
import { useParams, Redirect } from "react-router-dom";
import { getDynamicLink } from "../../data/routeUtils";
import { Info } from "./Info";
import { useWidth } from "../../hooks/useWidth";
import "./legends/Legends.scss";
import {
  Audio1933,
  DataUrl1933,
  Header1933,
  Highlights1933,
  Legends1933,
  Sources1933,
} from "./years/Year1933";
import {
  Header1941,
  DataUrl1941,
  Audio1941,
  Sources1941,
  Highlights1941,
  Legends1941,
} from "./years/Year1941";
import {
  Header1942,
  DataUrl1942,
  Audio1942,
  Sources1942,
  Highlights1942,
  Legends1942,
  Highlights1942Dec,
  Highlights1942Jan,
  Highlights1942Feb,
  Highlights1942March,
  Highlights1942April,
  Highlights1942May,
} from "./years/Year1942";
import {
  Header1945,
  DataUrl1945,
  Audio1945,
  Sources1945,
  Highlights1945,
  Legends1945,
  Additional1945,
} from "./years/Year1945";

export const ROUTE = "/empire-of-japan/:year?";

enum Orientation {
  PORTRAIT,
  LANDSCAPE,
}

export enum TimelineMonths {
  DEC = "December",
  JAN = "January",
  FEB = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
}

const story: SimpleStory = {
  dataUrl: "/maps/SupernovaInTheEast/sea_56_91_166_-20.json",
  imageUrl: "/maps/SupernovaInTheEast/final_56_91_166_-20.webp",
  years: {
    "1870": {
      year: 1870,
      header: Header1870,
      territoriesUrl: DataUrl1870,
      audio: Audio1870,
      sources: Sources1870,
      highlights: Highlights1870,
      legends: Legends1870,
      additional: Additional1870,
      scale: [2.7, 2],
    },
    "1905": {
      year: 1905,
      header: Header1905,
      territoriesUrl: DataUrl1905,
      audio: Audio1905,
      sources: Sources1905,
      highlights: Highlights1905,
      legends: Legends1905,
      scale: [2.1, 1.8],
    },
    "1933": {
      year: 1933,
      header: Header1933,
      territoriesUrl: DataUrl1933,
      audio: Audio1933,
      sources: Sources1933,
      highlights: Highlights1933,
      legends: Legends1933,
      scale: [1.3, 1.1],
    },
    "1941": {
      year: 1941,
      header: Header1941,
      territoriesUrl: DataUrl1941,
      audio: Audio1941,
      sources: Sources1941,
      highlights: Highlights1941,
      legends: Legends1941,
      scale: [1.3, 1.2],
    },
    "1942": {
      year: 1942,
      header: Header1942,
      territoriesUrl: DataUrl1942,
      audio: Audio1942,
      sources: Sources1942,
      highlights: Highlights1942,
      legends: Legends1942,
      scale: [1, 1.1],
    },
    "1945": {
      year: 1945,
      header: Header1945,
      territoriesUrl: DataUrl1945,
      audio: Audio1945,
      sources: Sources1945,
      highlights: Highlights1945,
      legends: Legends1945,
      additional: Additional1945,
      scale: [1, 1],
    },
  },
};

function getPromises(story: SimpleStory) {
  return [
    dataLibs.initD3(),
    dataLibs.initTopojson(),
    dataMaps.getTopo(story.dataUrl),
  ];
}

function getScale(width: number, yearItem?: any) {
  if (!yearItem) {
    return 1;
  }

  const mq = window.matchMedia("(orientation: landscape)");
  if (mq.matches && width > 767) {
    return yearItem.scale[Orientation.LANDSCAPE];
  }

  return yearItem.scale[Orientation.PORTRAIT];
}

const fetchedYears: { [key: string]: any } = {};

export const SupernovaInTheEast: React.FunctionComponent = () => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [data, setData] = useState<any[]>();
  const [active, setActive] = useRecoilState(mapActiveState);
  const [loading, setLoading] = useState<boolean>(true);
  const [month, setMonth] = useState<string>(TimelineMonths.DEC);
  const hoveredBeforeLoaded = useRef<boolean>(false);
  const [dynamic, setDynamic] = useState<{
    territories: any;
    year: string;
    scale: number;
  }>();

  const { year } = useParams<{
    year: string;
  }>();

  const width = useWidth();

  const load = useCallback(() => {
    const promises = getPromises(story);
    Promise.all(promises)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err);
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 1000);
  }, [load]);

  useEffect(load, [load]);

  useEffect(() => {
    if (data) {
      setStatus(Status.SUCCESS);
    }
  }, [data]);

  useEffect(() => {
    if (!year || !story.years[year]) {
      return;
    }

    setLoading(true);
    let animationDone = false;
    let fetchDone = false;
    let territories: any;
    let scale: 1;

    window.setTimeout(() => {
      animationDone = true;
      if (fetchDone) {
        if (hoveredBeforeLoaded.current) {
          hoveredBeforeLoaded.current = false;
          setActive(story.years[year].highlights.all);
        }
        setDynamic({
          year,
          territories,
          scale,
        });
        return;
      }
    }, 350);

    dataMaps
      .getTopo(story.years[year].territoriesUrl)
      .then((territoriesResponse) => {
        fetchedYears[year] = territoriesResponse;
        scale = getScale(width, story.years[year]);
        fetchDone = true;
        if (animationDone) {
          if (hoveredBeforeLoaded.current) {
            hoveredBeforeLoaded.current = false;
            setActive(story.years[year].highlights.all);
          }
          setDynamic({
            year,
            territories: territoriesResponse,
            scale,
          });
        } else {
          territories = territoriesResponse;
        }
      })
      .catch((err) => {
        console.error(err);
        // Todo, retry?
      });
  }, [year, width, setActive]);

  useEffect(() => {
    if (dynamic) {
      window.setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  }, [dynamic]);

  const onHover = useCallback(() => {
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      const dynamicYear = dynamic?.year;
      let highlights;

      if (dynamicYear) {
        if (dynamicYear === "1942") {
          if (month === TimelineMonths.DEC) {
            highlights = Highlights1942Dec;
          } else if (month === TimelineMonths.JAN) {
            highlights = Highlights1942Jan;
          } else if (month === TimelineMonths.FEB) {
            highlights = Highlights1942Feb;
          } else if (month === TimelineMonths.MARCH) {
            highlights = Highlights1942March;
          } else if (month === TimelineMonths.APRIL) {
            highlights = Highlights1942April;
          } else {
            highlights = Highlights1942May;
          }
        } else {
          highlights = story.years[dynamicYear]?.highlights;
        }

        setActive(highlights.all);
      } else {
        hoveredBeforeLoaded.current = true;
        setActive(null);
      }
    }
  }, [setActive, dynamic, month]);

  const offHover = useCallback(() => {
    hoveredBeforeLoaded.current = false;
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      setActive(null);
    }
  }, [setActive]);

  if (!year || !story.years[year]) {
    return (
      <Redirect
        to={getDynamicLink(ROUTE, { year: Object.keys(story.years)[0] })}
      />
    );
  }

  let highlights;
  if (dynamic?.year) {
    if (dynamic.year === "1942") {
      if (month === TimelineMonths.DEC) {
        highlights = Highlights1942Dec;
      } else if (month === TimelineMonths.JAN) {
        highlights = Highlights1942Jan;
      } else if (month === TimelineMonths.FEB) {
        highlights = Highlights1942Feb;
      } else if (month === TimelineMonths.MARCH) {
        highlights = Highlights1942March;
      } else if (month === TimelineMonths.APRIL) {
        highlights = Highlights1942April;
      } else {
        highlights = Highlights1942May;
      }
    } else {
      highlights = story.years[dynamic?.year]?.highlights;
    }
  }

  return (
    <Page
      title="Interactive map of Imperial Japan 1870 - 1945"
      canonical={`https://interactive-history.app/empire-of-japan/${year}`}
      description="Interactive map showing the territorial gains of Imperial Japan 1870 - 1945"
      mapSide={MapSide.LEFT}
      className={cx("supernova-in-the-east", {
        "is-loading": loading,
      })}
    >
      <img
        className="searchable-image"
        loading="lazy"
        src="/images/japan_1294x1294.webp"
        alt="Estimated borders of Imperial Japan 1870 - 1945"
      />
      <Retry status={status} retry={retry}>
        <>
          <MobileHeader year={year} title="Empire of Japan" />

          <div
            className="map-wrapper"
            onMouseEnter={onHover}
            onMouseLeave={offHover}
          >
            <Map
              year={year}
              land={data && data[2]}
              imageUrl={story.imageUrl}
              scale={dynamic?.scale}
              highlights={highlights}
              additional={
                dynamic?.year
                  ? story.years[dynamic?.year]?.additional
                  : undefined
              }
              territories={dynamic?.territories}
              isActive={active}
            />

            <MapDesktopHint />
          </div>

          <div className="info-wrapper">
            <Info
              year={dynamic?.year || Object.keys(story.years)[0]}
              story={story}
              month={month}
              setMonth={setMonth}
            />
          </div>
        </>
      </Retry>
    </Page>
  );
};
